<template>
  <v-footer
    dark
    color="secondary"
    class="footer-mobile"
  >
    <div class="footer__banner" />
    <div class="footer__bottom text-white position-relative">
      <a
        :href="URL_HASH_FOR_COOKIE_MANAGEMENT"
        class="img-cookie"
      >
        <img src="~/assets/images/cookie.svg" />
      </a>

      <div
        v-if="!isDiscontinued"
        class="mb-5"
      >
        <strong>Jeu avec obligation d'achat.</strong>
        <br />
        Valable du 08/04/2025 au 28/06/2025.
      </div>
      <div class="mb-5">
        <strong>Offre Boutique avec obligation d'achat.</strong>
        <br />
        Valable du 18/03/2024 au 31/12/2025.
      </div>
      <v-divider
        color="tertiary"
        class="mb-5 border-opacity-100"
      />
      <div class="text-tertiary">
        Pour votre santé, mangez au moins cinq fruits et légumes par jour.
        <a
          href="https://www.mangerbouger.fr"
          target="_blank"
        >
          www.mangerbouger.fr
        </a>
      </div>
      <div
        v-if="!isDiscontinued"
        class="mt-5"
      >
        ASTERIX® OBELIX® IDEFIX®/<br />
        © 2025 LES ÉDITIONS ALBERT RENÉ
      </div>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
import { URL_HASH_FOR_COOKIE_MANAGEMENT } from '@/utils/constants'
const { isDiscontinued } = useGame3()
</script>

<style lang="scss" scoped>
$background-image-width: 1440px;
$background-image-height: 40px;

.footer-mobile {
  flex-grow: 0;
  padding: 0;
  flex-direction: column;
}
.footer__banner {
  width: 100%;
  height: calc($background-image-height / 2);
  background-image: url('~/assets/images/background-footer.png');
  background-position: left top;
  background-size: calc($background-image-width / 2)
    calc($background-image-height / 2);
  background-repeat: repeat-x;
}

.footer__bottom {
  padding: 20px 20px 140px;

  font-size: 14px;
  line-height: 16px;

  margin: 0 0 30px 0;

  a {
    color: inherit;
  }
}

.img-cookie {
  display: flex;

  position: absolute;
  top: 20px;
  right: 20px;

  img {
    height: 40px;
    width: 40px;
  }
}
</style>
